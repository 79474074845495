.comment {
  word-break: break-word;
  color: var(--ui07);

  a {
    word-break: break-all;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.comment * {
  color: inherit !important;
}

.comment [data-mention='user'] {
  background: var(--mentionBg);
  padding: 0rem 0.3rem;
  color: var(--brand01);
  border-radius: 0.25rem;
}

.modal {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  outline: none;
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Ensure clicks outside the image are detected */
}

.fullSizeImage {
  max-width: 90%;
  max-height: 90%;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
}
