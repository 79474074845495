@media (hover: hover) {
  [role='row'] .icon {
    opacity: 0;
  }

  [role='row']:hover .icon {
    opacity: 1;
  }
}

[role='row'] .icon {
  opacity: 0;
}

[role='row'] > div[class*='selected'] .icon {
  opacity: 1;
}
