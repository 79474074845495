@use '@work4all/assets/theme/breakpoints.scss';

.root {
  @media (min-width: breakpoints.$lg) {
    display: flex;
    flex-direction: row;
  }
}

.panel {
  flex: 1;
}

.history [class*='container'] {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1rem;

  @media (max-width: breakpoints.$lg) {
    position: relative !important;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.textarea {
  min-height: 10rem;
}

.addSolutionBtn {
  margin-left: 2.5rem;
}

.solutionText {
  border-radius: 0.25rem;
  border-left: 0.25rem solid var(--success);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: breakpoints.$md) {
    flex-direction: row;
  }
}

.title {
  flex: 1;
}

.date {
  flex: 1;
  display: flex;
  gap: 1rem;
}

.flexItem {
  flex: 1;
}

.rightHeader {
  padding-left: 0;

  @media (max-width: breakpoints.$md) {
    padding-left: 1rem;
  }
}

.checkListRoot {
  padding-left: 1rem;
}

.errorText {
  color: var(--error) !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.textEditors {
  margin-top: 12px;
}

.dropZoneContainer {
  flex: 1;
}

.solutionTextWrapper {
  color: var(--ui07);
}

.solutionTextWrapper * {
  color: inherit !important;
}
